<template>
  <div v-if="step === 1">
    <div class="row d-md-flex justify-content-center">
      <div class="col-11 my-2 d-flex justify-content-center mx-auto">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="tenureType"
          :options="[
            { text: 'Monthly', value: 'MONTH' },
            { text: 'Yearly', value: 'YEAR' },
          ]"
          button-variant="outline-primary"
          name="radio-btn-outline"
          buttons
        />
      </div>
      <div class="col-12 col-md-3 price_card py-3" v-for="subscription in activeSubscriptions" :key="subscription.uuid">
        <div class="row">
          <div class="col-11 mx-auto">
            <b-img v-if="!isEmpty(subscription.image)" height="200" :src="subscription.image" class="mb-1" :alt="subscription.package_name" />
            <h3>
              <b>{{ subscription.package_name }}</b>
            </h3>
            <p v-if="isNumeric(subscription.allowed_orders) && ['PROVIDER', 'STORE'].includes(subscription.package_for)" class="text-muted">
              {{ subscription.allowed_orders }}
              <template v-if="subscription.package_for == 'PROVIDER'">bookings</template>
              <template v-else-if="subscription.package_for == 'STORE'">orders</template>
              per 
              <template v-if="subscription.tenure_type == 'MONTH'">month</template>
              <template v-else-if="subscription.tenure_type == 'YEAR'">year</template>
              at {{ subscription.package_amount }}$ every additional
              <template v-if="subscription.package_for == 'PROVIDER'">booking</template>
              <template v-else-if="subscription.package_for == 'STORE'">order</template>
              at {{ subscription.exceed_amount_per_order }}$
            </p>

            <p class="text-muted">
              {{ $t('Starting at') }}
            </p>
            <div class="d-flex justify-content-start align-items-center">
              <div class="mr-3px plan_price">{{ subscription.package_amount }}</div>
              <div>
                <p class="text-muted mb-0 mt-0 small_line_height">{{ $t('Per') }}</p>
                <p class="text-muted mb-0 mt-0 small_line_height">
                  <template v-if="tenureType == 'MONTH'">{{ $t('Month') }}</template>
                  <template v-else>{{ $t('Year') }}</template>
                </p>
              </div>
            </div>
            <p class="text-muted">
              {{ $t('Billed monthly based on usage') }}
            </p>

            <div v-if="!isEmpty(subscription.features)" class="mt-2">
              <p class="text-muted mb-0">
                {{ $t('This includes:') }}
              </p>
              <div v-for="(feature, fk) in subscription.features" :key="`feature_${fk}`" class="mb-3px">
                <b-icon :icon="feature.enabled ? 'check-circle-fill' : 'x-circle-fill'" :class="feature.enabled ? 'text-success' : 'text-danger'"></b-icon> {{ feature.label }}
              </div>
            </div>

            <button class="w-100 mom-button-1 mt-2" @click="upgrade(subscription)" :disabled="subscription.isActive">
              <template v-if="subscription.isActive">{{ $t('Current Plan') }}</template>
              <template v-else>{{ upgradeBtn }}</template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="step === 2">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-4">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>Sub total</td>
                <td>${{ sub_total }}</td>
              </tr>
              <tr>
                <td>
                  Discount
                  <template v-if="code_discount"> ({{ discount }}%)</template>
                </td>
                <td>${{ discount_amt }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>${{ sub_total - discount_amt }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="row d-md-flex justify-content-center">
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <b-input-group>
          <b-form-input placeholder="Referral/Coupon Code" v-model="code" :disabled="codeLoader || code_discount" />
          <b-input-group-append>
            <b-button v-if="!code_discount" variant="info" @click="_applyCode" :disabled="codeLoader">
              <template v-if="codeLoader">Appling...</template>
              <template v-else>Apply Code</template>
            </b-button>
            <b-button v-else variant="danger" @click="removeCode">Remove Code</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <div class="row d-md-flex mt-2 justify-content-center">
      <div class="col-12 col-md-4">
        <b-form-group :label="$t('Select Payment Gateway')" v-slot="{ ariaDescribedby }">
          <b-form-radio v-for="(pg, pgk) in paymentGateways" v-model="paymentGateway" :aria-describedby="ariaDescribedby" name="payment-gateways" :value="pg.value" :key="`pgk_${pgk}`" class="mt-2">
            <b-img height="30" :alt="pg.text" :src="`${apiUrl}${pg.image}`" />
          </b-form-radio>
        </b-form-group>
      </div>
      <div class="my-2 col-12 d-flex justify-content-between">
        <b-button variant="outline-primary" @click="step--">
          <template>{{ $t('Back') }}</template>
        </b-button>
        <b-button variant="success" @click="upgradeNow" :disabled="isEmpty(paymentGateway)">
          <template>{{ $t('Procceed') }}</template>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { isEmpty, isNumeric } from '@/utilities'
import { BButton, BRow, BCol, BImg, BFormRadioGroup } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SubscriptionList',
  components: {
    BButton,
    BRow,
    BCol,
    BImg,
    BFormRadioGroup,
  },
  props: {
    subscriptions: Array,
    upgradeBtn: {
      type: String,
      default: 'Upgrade',
    },
    allowUpgrade: {
      type: Boolean,
      default: false,
    },
    needConfirm: {
      type: Boolean,
      default: false,
      required: false,
    },
    activeSubscription: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data: () => ({
    codeLoader: false,
    tenureType: 'MONTH',
    sub_total: 0,
    discount: 0,
    discount_amt: 0,
    code: null,
    code_discount: false,
    step: 1,
    subscription: {},
    paymentGateway: null,
    apiUrl: process.env.VUE_APP_API_BASE_END_POINT,
  }),
  computed: {
    ...mapGetters('paymentGateway', ['activePaymentGateways']),
    paymentGateways: {
      get() {
        return this.activePaymentGateways.map(pg => ({
          text: pg.gateway.toUpperCase(),
          value: pg.id,
          image: pg.image,
        }))
      },
    },
    activeSubscriptions: {
      get() {
        return this.subscriptions.filter(i => i.status && i.tenure_type == this.tenureType)
      },
    },
    checkDowngrade: {
      get() {
        if (!this.needConfirm) return false
        if (this.activeSubscription?.plan?.package_amount > 0) {
          return true
        }
        return false
      },
    },
  },
  methods: {
    ...mapActions('coupon', ['applyCode']),
    isEmpty, isNumeric,
    upgrade(subscription) {
      if (this.checkDowngrade) {
        this.$swal({
          title: this.$t('Unused credits will be lost upon upgrade. Proceed?'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updatePlan(subscription)
          }
        })
      } else {
        this.updatePlan(subscription)
      }
    },
    upgradeNow() {
      const pg = this.activePaymentGateways.find(i => i.id == this.paymentGateway)
      const code = this.code_discount ? this.code : null
      this.$emit('upgradeSubscription', this.subscription, pg, code)
    },
    updatePlan(subscription) {
      if (this.allowUpgrade) {
        this.subscription = subscription
        if (this.subscription.package_amount > 0) {
          this.sub_total = parseFloat(this.subscription.package_amount)
          this.step++
        } else {
          const pg = this.activePaymentGateways.find((i, idx) => idx === 0)
          const code = this.code_discount ? this.code : null
          this.$emit('upgradeSubscription', this.subscription, pg, code)
        }
      } else {
        this.$emit('upgradeSubscription', subscription, null, null)
      }
    },
    _applyCode() {
      if (isEmpty(this.code)) return false
      this.codeLoader = true
      this.applyCode(this.code)
        .then(async r => {
          if (r._statusCode === 200) {
            this.code_discount = true
            const { discount } = r.responseData
            if (this.sub_total > 0) {
              const dis_amt = (this.sub_total * discount) / 100
              this.discount_amt = dis_amt
              this.discount = discount
            }
            showSuccessNotification(this, r.message)
          } else {
            showErrorNotification(this, r.message)
          }
          this.codeLoader = false
        })
        .catch(e => {
          showErrorNotification(this, 'Something went wrong!')
          this.codeLoader = false
        })
    },
    removeCode() {
      this.code = null
      this.code_discount = false
      this.discount_amt = 0
      this.discount = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.plan_price::before {
  content: '$';
}

.row,
p {
  font-size: 1rem !important;
}

.row {
  text-align: left;
}

.plan_price {
  font-size: 3.5rem;
}

.small_line_height {
  line-height: 1;
  font-size: 1.1rem !important;
}

.mb-3px {
  margin-bottom: 5px;

  .b-icon {
    margin-right: 3px;
  }
}

.mr-3px {
  margin-right: 3px;
}
</style>